import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import ProjectCards from '../components/project_components/ProjectCards'
import SimplePost from '../components/simple_post'
import NiceTitle from '../components/NiceTitle'

const Projects = ({ data }) => {
  const html = data.allMarkdownRemark.edges[0].node.html
  return (
    <>
      <SEO />
      <Layout>
        <NiceTitle
          title_name='About me'
        />
        <SimplePost
          html={html}
        />
        <NiceTitle
          title_name='Open Source Projects'
        />
        <ProjectCards />
      </Layout>
    </>
  )
}

Projects.propTypes = {
  data: PropTypes.object.isRequired
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: {fileAbsolutePath:
                    {regex: "/pages/about2.md/"}}){
      edges {
        node {
          html
        }
      }
    }
  }
`

export default Projects
