import React from "react"
import style from '../styles/index.module.css'
import HeadlineWithFocus from '../components/HeadlineWithFocus.js';

export default function NiceTitle({title_name}) {
  return (
    <div className="container">
      <HeadlineWithFocus>
      <div className={style.display_main}>
          <span style = {{color : "#FF8A00"}}>{title_name}</span>
      </div>
      </HeadlineWithFocus>
    </div>
  )
}
