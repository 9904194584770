import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Navigation from './navigation'
import { toKebabCase } from '../helpers'

import style from '../styles/post.module.css'

const SimplePost = ({
  excerpt,
  html
}) => {
  return (
    <div className={style.post}>
        {excerpt}
      <div className={style.postContent}>
        <div style={{textAlign : "justify"}}>
          {excerpt ? (
            <>
              <p>{excerpt}</p>
            </>
          ) : (
            <>
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

SimplePost.propTypes = {
  excerpt: PropTypes.string,
  html: PropTypes.string,
}

export default SimplePost
